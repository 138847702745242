import React from 'react';

import Layout from '../../components/layout';
import PendoJzbDecoder from '../../components/PendoJzbDecoder/pendo-jzb-decoder';
import SEO from '../../components/seo';

import ogImg from '../../images/og/tools-pendo-jzb-decoder-og.png';

const ToolsPage = () => (
  <Layout>
    <SEO
      title="Pendo jzb query param decoder"
      description="Decode and debug data that Pendo sends to their servers"
      ogUrl="https://tomekdev.com/tools/pendo-jzb-decoder"
      imageUrl={ogImg}
    />

    <section>
      <div className="container">
        <h1 className="pageTitle">Pendo jzb query param decoder</h1>

        <p>
          I work with Pendo from time to time and I wanted to validate if what I
          send is correct. Pendo hides the data they send behind{' '}
          <code>jzb</code> query param. It's encoded and not super-straight
          forward to decode.{' '}
        </p>
      </div>

      <div className="container">
        <p>
          <strong>This little tool will help you in decoding:</strong>
        </p>

        <PendoJzbDecoder />

        <h3 className="mt-40">Can' find jzb param?</h3>

        <p>
          Open dev tools and search for <em>data.pendo</em> then open the
          request and you'll find it under <em>Query String Parameters</em>.
          Copy it from there.
        </p>

        <h3>Any problem?</h3>

        <p>
          Please{' '}
          <a
            href="https://twitter.com/tomekdev_"
            rel="noreferrer"
            target="_blank"
          >
            DM me on Twitter.
          </a>
        </p>
      </div>
    </section>
  </Layout>
);

export default ToolsPage;
